<template>
  <div>
    <div class="offers">
      <v-container fluid>
        <div class="welcome">
          <h1>Offers Requiring Your Approval</h1>
        </div>

        <!-- Do some loading thing here -->
        <div class="loading" v-if="isLoading">
          <v-progress-circular
            indeterminate
            color="primary"
          ></v-progress-circular>
        </div>
        <!-- / Do some loading thing here -->

        <v-alert v-if="!isLoading && offers.length == 0" type="info"
          >Nothing to show here!</v-alert
        >

        <!-- List -->
        <div class="offers-list" v-if="!isLoading && offers.length">
          <offer-card
            v-for="(offer, i) in offers"
            :offer="offer"
            :key="i"
            @selected="navigateToOffer(offer)"
          ></offer-card>
        </div>
        <!-- / List -->
      </v-container>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import OfferCard from "../../../components/offers/OfferCard.vue";
export default {
  name: "Offers",

  components: { OfferCard },

  created() {
    this.fetch();
  },

  data() {
    return {};
  },

  computed: {
    ...mapGetters("offers", {
      isLoading: "isLoading",
      offers: "offers",
    }),
  },

  methods: {
    ...mapActions("offers", {
      fetch: "fetch",
    }),

    navigateToOffer(offer) {
      this.$router.push("/offers/" + offer.id);
    },
  },
};
</script>
<style lang="scss" scoped>
.loading {
  text-align: center;
  padding-top: 20px;
}

.offers {
  padding: 20px;

  .welcome {
    margin-bottom: 30px;
  }
}
</style>